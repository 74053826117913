import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import FormField from './FormField';
import styles from './FormComponent2.module.css';

const FormFieldsContainer = ({
  isLoading, // Add isLoading here  
  layoutConfig,
  fieldValues,
  handleFieldChange,
  handleDropdownChange,
  dropdownOptions,
  dropdownDependencies,
  fieldMetadata,
  disabledStates,
  disabledStatesInitialized,

}) => {
//  console.log('[FormFieldsContainer] Render');
//  console.log('[FormFieldsContainer] isLoading:', isLoading);
//  console.log('[FormFieldsContainer] disabledStatesInitialized:', disabledStatesInitialized);
//  console.log('[FormFieldsContainer] disabledStates:', disabledStates);

  const sortedLayoutConfig = useMemo(() => {
//    console.log('[FormFieldsContainer] Sorting layoutConfig');
    return [...layoutConfig].sort((a, b) => a.order - b.order);
  }, [layoutConfig]);
//  console.log('[FormFieldsContainer] sortedLayoutConfig:', sortedLayoutConfig);


  // Memoize the updated field configuration to prevent unnecessary renders
  const getUpdatedFieldConfig = useCallback((fieldConfig) => {
//    console.log(`[FormFieldsContainer] Getting updated field config for ${fieldConfig.id}`);
    let updatedFieldConfig = { ...fieldConfig };

    if (fieldConfig.type === 'dropdown') {
      updatedFieldConfig.options = dropdownOptions[fieldConfig.id] || [];
//      console.log(`[FormFieldsContainer] Updated options for dropdown '${fieldConfig.id}':`, updatedFieldConfig.options);

      const dynamicKey = fieldConfig.dynamicKey;
      if (dynamicKey && dropdownDependencies && dropdownDependencies[dynamicKey]) {
        const dependencyValue = fieldValues[dynamicKey];
        const dynamicOptions = dropdownDependencies[dynamicKey][dependencyValue] || [];
        updatedFieldConfig.dynamicOptions = dynamicOptions;
//        console.log(`[FormFieldsContainer] Updated dynamicOptions for dropdown '${fieldConfig.id}' based on '${dynamicKey}':`, dynamicOptions);
      }
    }

    return updatedFieldConfig;
  }, [dropdownOptions, dropdownDependencies, fieldValues]);

  const memoizedFieldConfigs = useMemo(() => {
//    console.log('[FormFieldsContainer] Memoizing field configs');
    const configs = sortedLayoutConfig.map(row => row.map(fieldConfig => getUpdatedFieldConfig(fieldConfig)));
    // Log the memoized field configurations for debugging
//    console.log('[FormFieldsContainer] memoizedFieldConfigs:', configs);
    return configs;
  }, [sortedLayoutConfig, getUpdatedFieldConfig]);

if (isLoading || !disabledStatesInitialized) {
//        console.log('[FormFieldsContainer] Still loading or waiting for disabled states...');
    return <div>Loading form fields...</div>; // Or any other placeholder you prefer
  }

//    console.log('[FormFieldsContainer] Rendering form fields...');

  return (
    <>
      {memoizedFieldConfigs.map((row, rowIndex) => (
        <div key={`row-${rowIndex}`} className={styles.formRow}>
          {row.map((updatedFieldConfig, fieldIndex) => {
            const fieldKey = `field-${rowIndex}-${fieldIndex}-${updatedFieldConfig.id}`;
//            console.log(`[FormFieldsContainer] Rendering field '${updatedFieldConfig.id}' with key '${fieldKey}'`);

            return (
              <Box key={fieldKey} className={styles.fieldContainer} sx={{ flex: 1 }}>
                <FormField
                  fieldConfig={updatedFieldConfig}
                  value={fieldValues[updatedFieldConfig.id]}
                  onFieldChange={handleFieldChange}
                  onDropdownChange={handleDropdownChange}
                  allFieldValues={fieldValues}
                  fieldMetadata={fieldMetadata[updatedFieldConfig.id]}
                  disabledStates={disabledStates}
                />
              </Box>
            );
          })}
        </div>
      ))}
    </>
  );
};

FormFieldsContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired, // Add isLoading to propTypes
  layoutConfig: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        required: PropTypes.bool,
        options: PropTypes.array,
        dynamicKey: PropTypes.string,
        dynamicOptions: PropTypes.array,
        maxLength: PropTypes.number,
        order: PropTypes.number,
      })
    )
  ).isRequired,
  fieldValues: PropTypes.object.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleDropdownChange: PropTypes.func.isRequired,
  dropdownOptions: PropTypes.object.isRequired,
  dropdownDependencies: PropTypes.object,
  fieldMetadata: PropTypes.object,
  disabledStates: PropTypes.object,
  disabledStatesInitialized: PropTypes.bool, // Include this in propTypes
};

export default FormFieldsContainer;
