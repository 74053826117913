import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownCircleOutlined from '@mui/icons-material/ArrowDropDownCircleOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // Import the plus icon
import DeleteIcon from '@mui/icons-material/Delete'; // Import the delete icon

function CustomToolbar({ title, onAddRow, onToggleDelete }) {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', fontSize: '1.0rem', paddingLeft: '10px' }}>
                <ArrowDropDownCircleOutlined style={{ color: primaryColor, marginRight: '10px' }} />
                <Typography variant="subtitle1" component="div" style={{ color: primaryColor, fontWeight: 'bold', lineHeight: '3' }}>
                    {title}
                </Typography>
            </div>
            <div>
                <IconButton
                    onClick={onAddRow} // Call the passed callback function on click
                    style={{ marginRight: '10px' }}
                >
                    <AddCircleOutlineIcon style={{ fontSize: '18px' }} />
                </IconButton>
                <IconButton
                    onClick={onToggleDelete} // Call the passed callback function for toggle delete
                    style={{ fontSize: '18px', marginRight: '10px' }}
                >
                    <DeleteIcon style={{ fontSize: '18px' }} />
                </IconButton>

            </div>
        </div>
    );
}

export default CustomToolbar;
