import React from 'react';
import { useDrop } from 'react-dnd';
import { DropZoneTypes } from './constants';

const DropZone = ({ onDrop, type, rowIndex, fieldIndex, rowKeys }) => {
    const [{ isOver }, drop] = useDrop({
        accept: 'field',
        drop: (item) => {
//            console.log(`DropZone ${type}: item ${item.id} dropped. Source row: ${item.rowIndex}, Source index: ${item.index}`);
//            console.log(`Target row (before adjustment): ${rowIndex}, Target index: ${fieldIndex}`);

            if (!rowKeys) {
                console.error("DropZone error: rowKeys is undefined");
                return;
            }

            let adjustedTargetRowIndex = rowIndex;
            let adjustedFieldIndex = fieldIndex;

            if (type === DropZoneTypes.BETWEENROWS) {
                adjustedTargetRowIndex = rowIndex;
//                console.log(`Adjusted target row index for drop between rows: ${adjustedTargetRowIndex}`);
            } else if (type === DropZoneTypes.ABOVE) {
                adjustedTargetRowIndex = rowIndex <= 0 ? -1 : rowIndex - 1;
//                console.log(`Adjusted target row index for drop above: ${adjustedTargetRowIndex}`);
            } else if (type === DropZoneTypes.BELOW) {
                adjustedTargetRowIndex = rowIndex >= rowKeys.length - 1 ? rowKeys.length : rowIndex + 1;
//                console.log(`Adjusted target row index for drop below: ${adjustedTargetRowIndex}`);
            }
            
                // Additional logging for LEFT and RIGHT to see if these cases are being triggered
            if (type === DropZoneTypes.LEFT) {
//                console.log(`DropZone LEFT at row ${rowIndex}, moving to index ${adjustedFieldIndex - 1}`);
                adjustedFieldIndex = Math.max(0, adjustedFieldIndex - 1); // Ensure the index doesn't go negative
            } else if (type === DropZoneTypes.RIGHT) {
//                console.log(`DropZone RIGHT at row ${rowIndex}, moving to index ${adjustedFieldIndex}`);
                // No adjustment needed for RIGHT as it should place the item after the current index
            }

            onDrop(item, type, adjustedTargetRowIndex, fieldIndex);
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
        }),
    });

    const style = {
        height: type === DropZoneTypes.ABOVE || type === DropZoneTypes.BELOW || type === DropZoneTypes.BETWEENROWS ? '10px' : '100%',
        width: type === DropZoneTypes.LEFT || type === DropZoneTypes.RIGHT ? '10px' : '100%',
        backgroundColor: isOver ? 'blue' : 'transparent',
        display: 'flex',
        alignSelf: 'center',
    };

    return <div ref={drop} style={style} />;
};

export default DropZone;
