//EditableTable.js
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase'; 
import { collection, getDocs } from 'firebase/firestore';
import React, { useState, useEffect, useRef } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import styles from './EditableTable.module.css';
import { Button, Snackbar, Alert, TextField } from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree'; // Default (outlined) version
import AccountTreeFilledIcon from '@mui/icons-material/AccountTree'; // Filled version
import DependencyManager from './DependencyManager';
import { updateDropdownOptions, getDropdownOptions, getDropdownDependencies, getFieldMetadata } from '../../utils/firestore';
import CustomToolbar from './CustomToolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGridApiRef } from '@mui/x-data-grid';

// Utility function placed outside the component
const applySorting = (rows, sortModel) => {
    if (!sortModel || sortModel.length === 0) return rows; // No sorting applied

//    console.log("Applying sorting", sortModel); // Log the sort model

    const sortedRows = [...rows];
    const { field, sort } = sortModel[0]; // Assuming single column sorting for simplicity

//    console.log(`Sorting by ${field} in ${sort} order`); // Log sorting details

    sortedRows.sort((a, b) => {
        if (a[field] < b[field]) return sort === 'asc' ? -1 : 1;
        if (a[field] > b[field]) return sort === 'asc' ? 1 : -1;
        return 0;
    });

//    console.log("Sorted rows sample", sortedRows.slice(0, 5)); // Log the first 5 sorted rows for inspection

    return sortedRows;
};


const EditableTable = ({ initialData, title, allFields, category, onDataChange }) => {
   //console.log("Initial data received:", initialData); // Add this line

    const dataGridRef = useRef(null); 
    const apiRef = useGridApiRef();
    const theme = useTheme();
    const [rows, setRows] = useState([]);
    const [sortModel, setSortModel] = useState([]);
    const [currentRow, setCurrentRow] = useState(null);
    const [openDependencyManager, setOpenDependencyManager] = useState(false);
    const [dropdownOptions, setDropdownOptions] = useState({});
    const [isDeleteMode, setIsDeleteMode] = useState(false);
    const [error, setError] = useState('');
    const [fieldMetadata, setFieldMetadata] = useState({}); // State to store field metadata


    // Fetch dropdown options, dependencies, and field metadata
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetching dropdown options and dependencies
                const options = await getDropdownOptions();
                const dependencies = await getDropdownDependencies();

                // Fetching field metadata from Firestore
                const fieldMetaSnapshot = await getDocs(collection(db, 'fieldMetadata'));
                let meta = {};
                fieldMetaSnapshot.forEach(doc => {
                    meta[doc.id] = doc.data();
                });

                // Ensure that each item in initialData has an 'id' and 'label' property
                const validatedData = initialData.filter(item => item && item.label);

                // Mapping validatedData to set rows
                const updatedRows = validatedData.map(item => ({
                    ...item,
                    id: item.id || item.label,
                    dependencies: dependencies.dynamicDependencies && dependencies.dynamicDependencies[item.label] ? dependencies.dynamicDependencies[item.label] : {}
                }));

                // Updating state with fetched data
                setDropdownOptions(options);
                setFieldMetadata(meta);
                setRows(updatedRows);

            } catch (err) {
                console.error('Error while fetching data:', err);
                setError('Failed to fetch data: ' + err.message);
            }
        };
        fetchData();
    }, [initialData]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dataGridRef.current && !dataGridRef.current.contains(event.target)) {
                setIsDeleteMode(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dataGridRef]);

    const handleSortModelChange = (newModel) => {
///    console.log('Sort model changed:', newModel);
        setSortModel(newModel);
    };

    // Function to transform row data before sending to Firestore, including 'order'
    const transformRowsForFirestore = (rows) => {
        return rows.map((row, index) => ({
            ...row,
            order: index // Ensuring each row has an 'order' property based on its position
        }));
    };

    const processRowUpdate = (newRow) => {
        // Check for duplicate labels excluding the current row being updated
        const isDuplicateLabel = rows.some(row => row.id !== newRow.id && row.label === newRow.label);

        if (isDuplicateLabel) {
            // Handle the duplicate label case
            setError('Duplicate label detected. Each option must have a unique label.');
            return null; // Returning null to prevent the grid from updating the row
        }

        // Update the row within the current rows array
        let updatedRows = rows.map((row) => (
            row.id === newRow.id ? { ...newRow, isNewRow: false } : row
        ));

        if (sortModel.length > 0) {
            // Apply sorting to the updated rows based on the current sort model if sorting is applied
            updatedRows = applySorting(updatedRows, sortModel);
        }

        // Update the 'order' property for each row based on its position after sorting
        updatedRows = updatedRows.map((row, index) => ({ ...row, order: index }));

        // Update the component state with the sorted and reordered rows
        setRows(updatedRows);

        // Notify the parent component of the changes, including the reordering
        onDataChange(category, transformRowsForFirestore(updatedRows));

        return newRow;
    };


const handleAddRow = () => {
    const newId = Date.now();
    const newRow = {
        id: newId,
        label: '', // Start with an empty label
        hint: 'Click to add hint',
        dependencies: {},
        isNewRow: true,
    };

    // Add the new row to the rows array
    let updatedRowsWithNew = [...rows, newRow];
    
    // Apply existing sorting if any
    updatedRowsWithNew = applySorting(updatedRowsWithNew, sortModel);

    // Update rows state with the new row included
    setRows(updatedRowsWithNew);

    // Focus on the new row for editing after a brief timeout
    setTimeout(() => {
        apiRef.current.setCellFocus(newId, 'label');
        apiRef.current.startCellEditMode({ id: newId, field: 'label' });
    }, 100);

    // No need to call onDataChange here as the row is still being edited
};



    const handleCellEditStart = (params, event) => {
        if (params.field === 'label' && params.row.isNewRow) {
            const updatedRows = rows.map(row => {
                if (row.id === params.id) {
                    return { ...row, label: '', isNewRow: false };
                }
                return row;
            });
            setRows(updatedRows);
            onDataChange(category, updatedRows); // Notify parent component about the change

        }
    };

    const handleOpenDependencyManager = (row) => {
        if (!dropdownOptions || Object.keys(dropdownOptions).length === 0) {
            setError('Dropdown options are not available.');
            return;
        }

        setCurrentRow(row);
        setOpenDependencyManager(true);
    };

    const handleCloseDependencyManager = () => {
        setOpenDependencyManager(false);
    };

    const handleDependencyUpdate = async (sourceField, dependentField, selectedOptions) => {
        try {
            
            // Fetch current dynamicDependencies from Firestore
            const currentDependenciesDoc = await getDropdownDependencies();
            const currentDependencies = currentDependenciesDoc.dynamicDependencies || {};


            // Check if selectedOptions is an array before mapping
            if (!Array.isArray(selectedOptions)) {
                console.error('Selected options is not an array:', selectedOptions);
                return; // Exit the function if selectedOptions is not an array
            }

            const updatedOptions = selectedOptions.map(option => 
                (typeof option === 'string') ? { label: option, hint: '' } : option
            );


            // Update the dependencies for the specific source field
            let updatedSourceFieldDependencies = {
                ...(currentDependencies[sourceField] || {}),
                [dependentField]: updatedOptions
            };

            // Remove the dependent field from dependencies if no options are selected
            if (updatedOptions.length === 0) {
                delete updatedSourceFieldDependencies[dependentField];
            }

            // Remove the source field from dependencies if it has no dependent fields left
            if (Object.keys(updatedSourceFieldDependencies).length === 0) {
                delete currentDependencies[sourceField];
            } else {
                currentDependencies[sourceField] = updatedSourceFieldDependencies;
            }


            // Update Firestore
            await setDoc(doc(db, 'dropdownDependencies', 'dynamicDependencies'), { dynamicDependencies: currentDependencies });


            // Refresh rows with updated dependencies
            const dependencies = await getDropdownDependencies();
            const updatedRows = rows.map(item => {

                // Update dependencies for the row that matches the source field
                if (item.label === sourceField) {
                    return {
                        ...item,
                        dependencies: dependencies.dynamicDependencies[item.label] || {}
                    };
                }
                return item;
            });
            setRows(updatedRows);
            onDataChange(category,updatedRows); // Notify parent component about the change
        } catch (error) {
            console.error('Error updating dependencies:', error);
        }
    };

    const toggleDeleteMode = () => {
        setIsDeleteMode((prevMode) => !prevMode);
    };

const handleDeleteRow = async (rowId) => {
    // Filter out the row to be deleted
    const updatedRowsAfterDelete = rows.filter(row => row.id !== rowId);

    // Apply current sorting to the updated rows array after deletion
    const sortedUpdatedRowsAfterDelete = applySorting(updatedRowsAfterDelete, sortModel);

    // Update the 'order' property based on the current sorting and position
    const reorderedRowsAfterDelete = sortedUpdatedRowsAfterDelete.map((row, index) => ({
        ...row,
        order: index
    }));

    // Update the state with the sorted and reordered rows after deletion
    setRows(reorderedRowsAfterDelete);

    console.log("Rows after deleting a row and reordering:", reorderedRowsAfterDelete); // Log the updated rows for debugging

    // Send the reordered rows to the parent component
    onDataChange(category, transformRowsForFirestore(reorderedRowsAfterDelete));
};



    const getColumns = () => {
        let columns = [
    {
        field: 'label', 
        headerName: 'Options', 
        width: 150, 
        editable: true, 
        flex: 1,
        renderCell: (params) => (
            <div style={{ color: params.row.isNewRow ? 'gray' : 'inherit' }}>
                {params.value}
            </div>
        ),
        renderEditCell: (params) => (
                    <TextField
                        autoFocus
                        placeholder="Click to add option"
                        variant="outlined"
                        fullWidth
                        value={params.value || ''} // Use the current cell value or an empty string if undefined
                        onChange={(e) => {
                            // Directly call setEditCellValue with the new value
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: e.target.value });
                        }}
                    />
                ),
    },
        { field: 'hint', headerName: 'Hint', width: 100, editable: true, flex: 1 },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 80,
        getActions: (params) => {
            // Check if the current row has defined dependencies
            const hasDependencies = params.row.dependencies && Object.keys(params.row.dependencies).length > 0;

            return [
                <GridActionsCellItem
                    icon={hasDependencies ? 
                          <AccountTreeFilledIcon style={{ fontSize: '14px', color: theme.palette.primary.main }} /> : 
                          <AccountTreeIcon style={{ fontSize: '14px' }} />}
                    label="Manage Dependencies"
                    onClick={() => handleOpenDependencyManager(params.row)}
                />
            ];
        },
    },
    ];

        if (isDeleteMode) {
            columns.push({
                field: 'delete',
                headerName: 'Delete',
                sortable: false,
                width: 80,
                disableColumnMenu: true, // Disable the column menu to prevent the tooltip
                renderCell: (params) => (
                    <GridActionsCellItem
                        icon={<DeleteIcon style={{ fontSize: '14px' }} />}
                        label="Delete" // Ensure this label is defined
                        onClick={() => handleDeleteRow(params.id)}
                    />
                ),
            });
        }

        return columns;
    };

    return (
        <div style={{ height: 1200, width: '100%' }}>
            <DataGrid
                ref={dataGridRef}
                apiRef={apiRef}
                className={styles.dataGrid}
                rows={rows}
                columns={getColumns()}
                pageSize={5}
                onCellEditStart={handleCellEditStart}
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{
                    toolbar: {
                        title: title,
                        onAddRow: handleAddRow,
                        onToggleDelete: toggleDeleteMode
                    }
                }}    
                disableSelectionOnClick
                processRowUpdate={processRowUpdate}
                getRowClassName={(params) => params.row.dependencies && Object.keys(params.row.dependencies).length > 0 ? 'row-with-dependencies' : ''}
                rowHeight={35} // You can adjust this value as needed
                hideFooter={true} // Add this line to disable pagination
                sx={{
                    '& .MuiDataGrid-root': {
                        border: 0,
                        borderRadius: '0px !important',
                    },
                    '.MuiDataGrid-columnHeaders': {
                        backgroundColor: '#f5f5f5',
                    },
                    '& .MuiDataGrid-cell[data-field="hint"]': {
                        color: 'gray',
                    },
                    // Highlight the sorted column header
                    '& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-columnHeaderTitle': {
                        color: '#1976d2', // Blue color to match the sorting icon                        
                        fontWeight: 'bold',
                    },
                    // Style the sorting icon
                    '& .MuiDataGrid-sortIcon': {
                        color: '#1976d2', // Example color
                        fontSize: '1rem',
                    },
                }}
                onProcessRowUpdateError={(err) => {
                    setError(err.message);
                }}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
            />

            {openDependencyManager && (
                <DependencyManager
                    open={openDependencyManager}
                    onClose={handleCloseDependencyManager}
                    onSave={handleDependencyUpdate}
                    fields={allFields}
                    currentRow={currentRow}
                    optionsForDependentField={dropdownOptions}
                    fieldMetadata={fieldMetadata}

                />
            )}

            {/* Snackbar for displaying error messages */}
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>            
        </div>
    );
};

export default EditableTable;
