// src/pages/SubmitSettings.jsx
import React, { useState, useEffect } from 'react';
import { getNamingStructure, saveNamingStructure } from '../utils/firestore';
import NameGenerator from '../components/FormSettings/NameGenerator'; 

const SubmitSettings = () => {
  const [currentStructure, setCurrentStructure] = useState('');

  useEffect(() => {
    const loadNamingStructure = async () => {
      const structure = await getNamingStructure();
      setCurrentStructure(structure);
    };

    loadNamingStructure();
  }, []);

  const handleSaveStructure = (newStructure) => {
    saveNamingStructure(newStructure).then(() => {
      console.log('Structure saved successfully');
      // Optionally update the state or provide user feedback
    }).catch(error => {
      console.error('Error saving structure:', error);
      // Handle errors, e.g., show a notification to the user
    });
  };

  return (
    <div>
      <h1>Form Submit Settings</h1>
      <NameGenerator
        currentStructure={currentStructure}
        onSaveStructure={handleSaveStructure}
      />
    </div>
  );
};

export default SubmitSettings;
