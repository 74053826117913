import React from 'react';
import FormDesign2 from '../components/FormSettings/FormDesign/FormDesign2';

const FormLayout = () => {
    return (
        <div style={{ margin: '20px' }}>
            <h1>Form Layout Settings</h1>
            <p>Use the interface below to rearrange the form fields as needed.</p>
            <FormDesign2 />
        </div>
    );
};

export default FormLayout;
