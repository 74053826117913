import { useState, useEffect, useCallback } from 'react';
import { subscribeToDropdownOptions, subscribeToDropdownDependencies, getDropdownOptions, getDropdownDependencies } from '../../../utils/firestore';

const useDropdownOptions = () => {
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [dropdownDependencies, setDropdownDependencies] = useState({});
  const [fullOptions, setFullOptions] = useState({}); // Cache full options to avoid unnecessary fetches

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        console.log('Fetching initial dropdown options and dependencies...');
        const [initialOptions, initialDependencies] = await Promise.all([
          getDropdownOptions(),
          getDropdownDependencies(),
        ]);

        console.log('Initial dropdown options fetched:', initialOptions);
        console.log('Initial dropdown dependencies fetched:', initialDependencies);

        setDropdownOptions(initialOptions);
        setDropdownDependencies(initialDependencies);
        setFullOptions(initialOptions); // Cache the full options on initial fetch
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchDropdownData();

    const optionsUnsubscribe = subscribeToDropdownOptions(newOptions => {
      console.log('New dropdown options received via subscription:', newOptions);
      setDropdownOptions(newOptions);
      setFullOptions(newOptions); // Update the cached full options on subscription update
    });

    const dependenciesUnsubscribe = subscribeToDropdownDependencies(newDependencies => {
      console.log('New dropdown dependencies received via subscription:', newDependencies);
      setDropdownDependencies(newDependencies);
    });

    return () => {
      console.log('Cleaning up subscriptions...');
      optionsUnsubscribe();
      dependenciesUnsubscribe();
    };
  }, []);

  const updateOptionsBasedOnDependencies = useCallback((fieldName, labelValue) => {
    console.log(`Updating options based on dependencies for field "${fieldName}" with new value:`, labelValue);

    const updatedOptions = { ...dropdownOptions }; // Start with the current options

    if (dropdownDependencies.dynamicDependencies && dropdownDependencies.dynamicDependencies[labelValue]) {
      const dependencies = dropdownDependencies.dynamicDependencies[labelValue];
      console.log(`Found specific dependencies for value "${labelValue}":`, dependencies);

      Object.keys(dependencies).forEach(depField => {
        updatedOptions[depField] = dependencies[depField];
        console.log(`Updated "${depField}" based on dependencies from "${labelValue}"`);
      });
    } else {
      console.log(`No specific dependencies found for "${labelValue}", checking for fields to reset...`);

      Object.entries(dropdownDependencies.dynamicDependencies || {}).forEach(([depValue, depFields]) => {
        if (depFields[fieldName]) {
          Object.keys(depFields).forEach(depField => {
            updatedOptions[depField] = fullOptions[depField]; // Use cached full options for resetting
            console.log(`Reset "${depField}" to full options because "${fieldName}" no longer has specific dependencies.`);
          });
        }
      });
    }

    console.log('Updated dropdown options:', updatedOptions);
    setDropdownOptions(updatedOptions); // Update the dropdown options with the new state
  }, [dropdownOptions, dropdownDependencies, fullOptions]);

  return {
    dropdownOptions,
    dropdownDependencies,
    updateOptionsBasedOnDependencies,
  };
};

export default useDropdownOptions;
