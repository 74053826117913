import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EditableTable from '../components/DropdownManagement/EditableTable';
import { CircularProgress, Snackbar, Alert, Button, Typography, Box } from '@mui/material';
import { getDropdownOptions, updateDropdownOptions, getDropdownDependencies, updateDropdownDependencies, getFieldMetadata } from '../utils/firestore';
import styles from './DropdownManagement.module.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const DropdownManagement = () => { 
 // console.log("Rendering DropdownManagement component");

  const isProduction = process.env.NODE_ENV === 'production'; // Environment check
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [dropdownDependencies, setDropdownDependencies] = useState({});
  const [fieldLabels, setFieldLabels] = useState({}); // State for storing field metadata labels
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

useEffect(() => {
  const fetchData = async () => {
    setLoading(true);

    try {
      // Fetch dropdown options, dependencies, and field metadata
      const [options, dependencies, metadata] = await Promise.all([
        getDropdownOptions(),
        getDropdownDependencies(),
        getFieldMetadata() // Fetching field metadata
      ]);

      setDropdownOptions(options);
      setDropdownDependencies(dependencies);
      setFieldLabels(metadata); // Setting field metadata labels
    } catch (err) {
      console.error('Error fetching data from Firebase:', err);
      setError('Failed to fetch data from the server.');
    } finally {
      setLoading(false);
    }
  };

  fetchData();
}, []);


  const handleChange = (category, updatedData) => {
    if (!isProduction && Array.isArray(updatedData)) { // Check if not in production
        setDropdownOptions(prevOptions => {
            const newOptions = { ...prevOptions, [category]: updatedData };
            return newOptions;
        });
        setHasUnsavedChanges(true);
    } else if (isProduction) {
   //     console.log(`Editing disabled in production for '${category}'`);
    } else {
        console.error(`handleChange - Incorrect data type for '${category}'. Expected an array, received:`, typeof updatedData);
    }
  };

  const handleDataChange = (category, data) => {
    handleChange(category, data);
  }

const handleSave = async () => {
  if (isProduction) return; // Prevent saving in production

  setSaving(true);
  try {
    console.log("Starting save operation...");

    for (const category in dropdownOptions) {
      console.log(`Processing category: ${category}`);
      
      // Log the current state of options for this category
      console.log("Current options:", dropdownOptions[category]);

      // If you want to preserve the order property in Firestore, ensure it's included in the optionsToSave
      const optionsToSave = dropdownOptions[category].map((option, index) => ({
        ...option,
        order: index // Ensure the order is explicitly set based on the current array index
      }));

      // Log the options prepared for saving, to verify the order property is correctly set
      console.log("Options prepared for saving:", optionsToSave);

      await updateDropdownOptions(category, optionsToSave);
    }

    if (typeof dropdownDependencies === 'object' && !Array.isArray(dropdownDependencies)) {
      console.log("Updating dropdown dependencies...");
      await updateDropdownDependencies(dropdownDependencies);
    } else {
      console.error('Dependencies data structure is incorrect:', dropdownDependencies);
    }

    console.log("Save operation successful.");
    setSuccess('All changes saved successfully.');
    setHasUnsavedChanges(false);
  } catch (err) {
    console.error('Save error:', err);
    setError('Failed to save changes. ' + err.message);
  } finally {
    setSaving(false);
    setSnackbarOpen(true);
  }
};



  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
  //console.log("Loading data...");

    return <CircularProgress />;
  }

 // console.log("Data loaded:", { dropdownOptions, dropdownDependencies });


  return (
    <div>
      <div className={styles.headerSection}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            style={{ padding: '0', marginRight: '8px' }}
            onClick={handleBack}
          >
            <Typography variant="text" component="span" className={styles.subHeader}>
              Back
            </Typography>
          </Button>

          <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}> 
            {saving && <CircularProgress size={24} style={{ marginRight: 15 }} />}
            <Button
              variant="contained"
              disabled={!hasUnsavedChanges || saving || isProduction}
              onClick={handleSave}
              style={{
                backgroundColor: hasUnsavedChanges ? '#FFA726' : '#E0E0E0',
                color: hasUnsavedChanges ? 'white' : '#A0A0A0',
                boxShadow: 'none',
                padding: '6px 16px',
                ':hover': {
                  backgroundColor: hasUnsavedChanges ? '#FFB74D' : '#E0E0E0',
                  boxShadow: 'none'
                }
              }}
            >
              {isProduction ? "Read Only" : "Save"}
            </Button>
          </Box>
        </Box>
      </div>
      

      <div className={styles.tablesContainer}>
        {Object.keys(dropdownOptions).map((category) => (
            <div key={category} className={styles.editableTable}>
            <EditableTable
          title={fieldLabels[category]?.label || category} // Use label from field metadata or fallback to category
              initialData={dropdownOptions[category] || []}
              onDataChange={handleDataChange}
              dependencyConfig={dropdownDependencies}
              category={category} 
              allFields={Object.keys(dropdownOptions)}
              readOnly={isProduction} // Added readOnly prop
            />
          </div>
        ))}
      </div>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={error ? "error" : "success"} sx={{ width: '100%' }}>
          {error || success}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DropdownManagement;
