import React, { useState, useEffect, useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, CircularProgress, FormControl } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { debounce } from 'lodash';

const URLFieldComponent = memo(({
  fieldId,
  fieldMetadata = {},
  value: propValue,
  onChange,
  disabled = false,
  submitted = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [internalError, setInternalError] = useState(false);
  const [internalErrorMessage, setInternalErrorMessage] = useState('');
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const {
    label = '',
    required = false,
    hint = '',
    helperText = '',
    externalError = false,
    externalHelperText = '',
  } = fieldMetadata;

  const validate = useMemo(() => (url) => {
    const urlRegex = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
    return urlRegex.test(url);
  }, []);

  useEffect(() => {
    if (submitted && required && !value) {
      setInternalError(true);
      setInternalErrorMessage('This field is required');
    } else if (value && !validate(value)) {
      setInternalError(true);
      setInternalErrorMessage('Please enter a valid URL (starting with http:// or https://)');
    } else {
      setInternalError(false);
      setInternalErrorMessage('');
    }
  }, [submitted, required, value, validate]);

  const debounceOnChange = useCallback(debounce((nextValue) => {
    onChange(nextValue);
  }, 300), [onChange]);

  const handleChange = useCallback((event) => {
    const nextValue = event.target.value;
    setValue(nextValue);
    debounceOnChange(nextValue);
  }, [debounceOnChange]);

  const endAdornment = useMemo(() => (
    <InputAdornment position="end">
      {loading && <CircularProgress size={20} />}
      {!loading && !internalError && !externalError && value && <CheckCircleOutlineIcon color="success" />}
      {!loading && (internalError || externalError) && <ErrorOutlineIcon color="error" />}
    </InputAdornment>
  ), [loading, internalError, externalError, value]);

  const finalHelperText = externalError ? externalHelperText : (internalError ? internalErrorMessage : helperText);

  return (
    <FormControl fullWidth>
      <TextField
        label={label}
        variant="outlined"
        value={value}
        onChange={handleChange}
        error={externalError || internalError}
        helperText={finalHelperText}
        InputProps={{ endAdornment }}
        disabled={disabled}
        required={required}
        title={hint}
      />
    </FormControl>
  );
});

URLFieldComponent.propTypes = {
  fieldId: PropTypes.string.isRequired,
  fieldMetadata: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
    hint: PropTypes.string,
    helperText: PropTypes.string,
    externalError: PropTypes.bool,
    externalHelperText: PropTypes.string,
  }),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  submitted: PropTypes.bool,
};

export default URLFieldComponent;
