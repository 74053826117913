import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete, FormControl, InputAdornment, IconButton, styled } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CustomFieldWrapper from './CustomFieldWrapper';
import AdditionalTextInput from './AdditionalTextInput'; 

const OptionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '& > span': {
    fontSize: '0.875rem',
  },
}));

const HintText = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const DropdownFieldComponent = ({
  fieldId, 
  fieldMetadata, 
  options,
  dynamicOptions, 
  value,
  onChange,
  submitted,
  disabled = false, 
  externalHelperText = '',
  isSubmitting = false, // Add isSubmitting to your component's props
}) => {

  const {
    label,
    hint,
    helperText,
    required,
    includeOther,
    maxLength,
  } = fieldMetadata;

  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const [additionalText, setAdditionalText] = useState(''); // State to store additional text

  useEffect(() => {
//    console.log(`[Effect] Dynamic options updated:`, dynamicOptions); // Log dynamicOptions changes

    if (dynamicOptions && dynamicOptions.length > 0) {
      setInputValue('');
    }
  }, [dynamicOptions]);

  useEffect(() => {
    if (inputValue === 'Other' && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputValue]);


  // Updated handleAutocompleteChange to include transformation
const handleAutocompleteChange = (_, newValue) => {
//    console.log(`[handleAutocompleteChange] New value:`, newValue); // Log new value selection

    if (newValue?.label === 'Other') {
        setInputValue('Other');
        onChange({ label: '' }); // Clear the value when 'Other' is selected
//        console.log(`[handleAutocompleteChange] Set 'Other' as input value`);
    } else {
        // Retain the label without applying transformations immediately
        onChange(newValue);
//        console.log(`[handleAutocompleteChange] onChange called with:`, newValue);
    }
};


  const actualOptions = Array.isArray(dynamicOptions) ? dynamicOptions : Array.isArray(options) ? options : [];
  const dropdownOptions = includeOther ? [...actualOptions, { label: 'Other' }] : actualOptions;
//  console.log(`[Dropdown Options]`, dropdownOptions); // Log the final options available in the dropdown

  const selectedOption = dropdownOptions.find(option => option.label === value?.label) || null;
//  console.log(`[Selected Option]`, selectedOption); // Log the selected option based on the value

  const applyTransformations = (inputValue) => {
//    console.log(`[applyTransformations] Initial inputValue:`, inputValue);

    const { capitalize, spacing, allowSymbols } = fieldMetadata.transformations || {};

    switch (capitalize) {
      case 'first-letter':
        inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1).toLowerCase();
        break;
      case 'all-caps':
        inputValue = inputValue.toUpperCase();
        break;
      case 'no-caps':
        inputValue = inputValue.toLowerCase();
        break;
      case 'no restrictions':
      default:
        break;
    }

    switch (spacing) {
      case 'underscore':
        inputValue = inputValue.replace(/\s+/g, '_');
        break;
      case 'hyphen':
        inputValue = inputValue.replace(/\s+/g, '-');
        break;
      case 'no-spacing':
        inputValue = inputValue.replace(/\s+/g, '');
        break;
      case 'no restrictions':
      default:
        break;
    }

    if (!allowSymbols) {
      inputValue = inputValue.replace(/[^\w\s-]/gi, '');
    }

    return inputValue;
  };

  const handleTextFieldChange = (event) => {
    const formattedValue = applyTransformations(event.target.value);
    onChange({ label: formattedValue });
//    console.log(`[handleTextFieldChange] Formatted value:`, formattedValue); // Log the formatted value on text field change

  };
//newcode
  const handleAdditionalTextChange = (text) => {
    setAdditionalText(text); // Update the additional text state
//    console.log(`[handleAdditionalTextChange] Additional text:`, text);
    // Assuming `onChange` is designed to handle an object with both main value and additional text
    onChange({ label: value?.label, additionalText: text });
//    console.log(`[handleAdditionalTextChange] onChange called with main label and additional text:`, { label: value?.label, additionalText: text });

  };

//end new code

  const handleBlur = () => {
//    console.log(`[handleBlur] Input value:`, inputValue); // Log on blur

    if (inputValue === 'Other' && !value?.label) {
      setInputValue('');
    }
  };

  const handleClear = () => {
    setInputValue('');
    onChange(null);
//    console.log(`[handleClear] Cleared input value and called onChange with null`); // Log the clear action

  };

  const isValidValue = actualOptions.some(option => option.label === value?.label) || (includeOther && value?.label);
  const showError = submitted && required && !isValidValue;
  const formattedLabel = required ? `${label} *` : label;

const renderCustomInput = (params) => {
  const inputProps = params.InputProps || {};

  return (
    <CustomFieldWrapper
      {...params}
      label={formattedLabel}
      placeholder={hint || ''}
      helperText={showError ? 'This field is required' : helperText}
      error={showError}
      variant="outlined"
      disabled={disabled || isSubmitting}
      InputProps={{
        ...inputProps,
        endAdornment: (
          <>
            {value?.label && (
              <IconButton onClick={handleClear} size="small">
                <ClearIcon />
              </IconButton>
            )}
            {inputProps.endAdornment}
          </>
        ),
      }}
    />
  );
};

return (
  <FormControl fullWidth required={required} error={showError}>
    {inputValue === 'Other' ? (
      <CustomFieldWrapper
        label={formattedLabel}
        value={value?.label || ''}
        onChange={handleTextFieldChange}
        onBlur={handleBlur}
        helperText={showError ? 'This field is required' : helperText}
        error={showError}
        variant="outlined"
        disabled={disabled || isSubmitting}
        placeholder="Type your answer" // Add this placeholder
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {inputValue === 'Other' && (
                <IconButton edge="end" onClick={handleClear} size="small">
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        autoFocus={inputValue === 'Other'}
      />
    ) : (
    <>
      <Autocomplete
        options={dropdownOptions}
        value={selectedOption}
        onChange={handleAutocompleteChange}
        renderInput={renderCustomInput}
        renderOption={(props, option) => (
          <li {...props}>
            <OptionContainer>
              <span>{option.label}</span>
              {option.hint && <HintText>{option.hint}</HintText>}
            </OptionContainer>
          </li>
        )}
        disableClearable
        autoHighlight
        autoSelect
        fullWidth
        isOptionEqualToValue={(option, val) => option.label === val?.label}
        disabled={disabled}
      />
          <AdditionalTextInput
        allowAdditionalText={fieldMetadata.allowAdditionalText}
        selectedValue={value?.label}
        onAdditionalTextChange={handleAdditionalTextChange}
        transformations={fieldMetadata.transformations}
        disabled={disabled || isSubmitting}
          />
        </>
    )}
  </FormControl>
);
};

DropdownFieldComponent.propTypes = {
  fieldId: PropTypes.string.isRequired,
  fieldMetadata: PropTypes.shape({
    label: PropTypes.string,
    hint: PropTypes.string,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    includeOther: PropTypes.bool,
    maxLength: PropTypes.number,
    transformations: PropTypes.object, // Include transformations in propTypes
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      hint: PropTypes.string,
    })
  ),
  dynamicOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      hint: PropTypes.string,
    })
  ),
  value: PropTypes.shape({
    label: PropTypes.string,
    hint: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
  disabled: PropTypes.bool,
  externalHelperText: PropTypes.string,
  isSubmitting: PropTypes.bool, // Add isSubmitting to your propTypes  
};

export default DropdownFieldComponent;
