// ExportOptions.jsx
import React from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { SaveAlt as SaveAltIcon } from '@mui/icons-material';

const ExportOptions = ({ data, columns }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    // Header row
    str += columns.filter(col => col.isVisible).map(col => `"${col.label}"`).join(',') + '\r\n';

    // Data rows
    array.forEach((row) => {
      let line = '';
      columns.forEach((col, index) => {
        if (col.isVisible) {
          if (line !== '') line += ',';
          line += `"${row[col.id] || ''}"`;
        }
      });
      str += line + '\r\n';
    });

    return str;
  };

  const exportCSV = () => {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    handleClose();
  };

  return (
    <div>
      <Tooltip title="Export Options">
        <IconButton onClick={handleClick}>
          <SaveAltIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={exportCSV}>Export to CSV</MenuItem>
        {/* Future MenuItem for Google Sheets can be added here */}
      </Menu>
    </div>
  );
};

export default ExportOptions;
