import { getNamingStructure, getFieldMetadata } from '../../utils/firestore';

const generateUTM = async (fieldValues) => {
  // Fetch naming structure and field metadata
  const [namingStructure, metadata] = await Promise.all([
    getNamingStructure(),
    getFieldMetadata(),
  ]);

  if (!namingStructure || !metadata) {
    console.error("Missing naming structure or field metadata");
    return '';
  }

  return parseAndGenerateUTM(namingStructure, metadata, fieldValues);
};

const parseAndGenerateUTM = (structure, metadata, fieldValues) => {
  const structureElements = structure.split(/({{[^}]+}})/).filter(Boolean);
  let utm = structureElements.map(element => {
    if (element.startsWith('{{') && element.endsWith('}}')) {
      const label = element.slice(2, -2).trim();
      const metadataKey = Object.keys(metadata).find(key => metadata[key].label === label);

      if (!metadataKey) {
        console.warn(`No metadata found for label: ${label}`);
        return '';
      }

      const fieldValue = fieldValues[metadataKey];
      if (!fieldValue) {
        console.warn(`No value found for field: ${metadataKey}`);
        return '';
      }

      // Check if the field should be encoded
      if (metadata[metadataKey].transformations?.encode) {
        // Apply encodeURIComponent to the field value
        return encodeURIComponent(fieldValue);
      }

      // Return the field value directly without encoding
      return fieldValue;
    }

    // Return the element directly without encoding
    return element;
  }).join('');

  return utm;
};


export default generateUTM;
