// DataSubmissionService.js
import { db } from '../firebase'; // Make sure this path is correct
import { collection, addDoc, getDocs, doc } from 'firebase/firestore';

const DataSubmissionService = {
  async submitToFirestore(formData) {
    try {
      // Retrieve field metadata
      const metadataSnapshot = await getDocs(collection(db, 'fieldMetadata'));
      const metadata = {};
      metadataSnapshot.forEach(doc => {
        metadata[doc.id] = doc.data();
      });

      // Prepare submission with metadata
      const submissionWithMetadata = {
        submittedAt: new Date(),
        responses: {}
      };

      for (const field in formData) {
        submissionWithMetadata.responses[field] = {
          value: formData[field],
          metadata: metadata[field] || {} // Use empty object if no metadata found
        };
      }

      // Submit to Firestore
      await addDoc(collection(db, 'formSubmissions'), submissionWithMetadata);
      console.log('Data submitted to Firestore with metadata');
    } catch (error) {
      console.error('Error submitting to Firestore:', error);
      throw error; // Throw error to handle it in the component if needed
    }
  },

  // Function to retrieve form submissions
  async fetchFormSubmissions() {
    try {
      const submissionsSnapshot = await getDocs(collection(db, 'formSubmissions'));
      const submissions = [];
      submissionsSnapshot.forEach(doc => {
        submissions.push({ id: doc.id, ...doc.data() });
      });
      return submissions;
    } catch (error) {
      console.error('Error fetching submissions:', error);
      throw error;
    }
  },

  // ... other submission functions as needed ...
};

export default DataSubmissionService;
