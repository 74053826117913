import React from 'react';
import { TableRow, TableCell, Tooltip } from '@mui/material';

const CustomTableRow = ({ row, columns, index }) => {
  const formatData = (data, columnId) => {
    return data; // Your existing formatting logic
  };

  const rowStyle = {
    minHeight: '24px', // Minimum height for the row
    maxHeight: '48px', // Maximum height for the row
    backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5', // Zebra striping colors
  };

  const cellStyle = {
    minHeight: '24px !important', // Ensures cell respects row's minimum height
    maxHeight: '48px !important', // Ensures cell respects row's maximum height    
    overflow: 'hidden', // Prevents content from overflowing
    paddingTop: '5px', // Padding top
    paddingBottom: '5px', // Padding bottom
    fontSize: '0.75rem', // Font size
  };

  return (
    <TableRow hover style={rowStyle}>
      {columns.map((column) => (
        <TableCell key={column.id} style={cellStyle}>
          <Tooltip title={row[column.id]} enterDelay={500} leaveDelay={200}>
            <span>{formatData(row[column.id], column.id)}</span>
          </Tooltip>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default CustomTableRow;