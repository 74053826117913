import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@mui/material';
import CustomFieldWrapper from './CustomFieldWrapper';
import debounce from 'lodash/debounce'; // Assuming lodash is available, or use a custom debounce function

const TextFieldComponent = ({
  fieldId,
  fieldMetadata,
  value: propValue,
  onChange,
  disabled = false,
  isSubmitting = false,
}) => {
  const [internalValue, setInternalValue] = useState(propValue);

  useEffect(() => {
//    console.log(`[TextFieldComponent - ${fieldId}] useEffect: propValue updated to: ${propValue}`);
    setInternalValue(propValue);
  }, [propValue]);

  const applyTransformations = useCallback((inputValue) => {
//    console.log(`[TextFieldComponent - ${fieldId}] applyTransformations: initial value: ${inputValue}`);
    const { capitalize, spacing, allowSymbols } = fieldMetadata.transformations || {};
    let transformedValue = inputValue;

    switch (capitalize) {
      case 'first-letter':
        transformedValue = transformedValue.charAt(0).toUpperCase() + transformedValue.slice(1).toLowerCase();
        break;
      case 'all-caps':
        transformedValue = transformedValue.toUpperCase();
        break;
      case 'no-caps':
        transformedValue = transformedValue.toLowerCase();
        break;
      default:
        break; // No capitalization transformation
    }

    switch (spacing) {
      case 'underscore':
        transformedValue = transformedValue.replace(/\s+/g, '_');
        break;
      case 'hyphen':
        transformedValue = transformedValue.replace(/\s+/g, '-');
        break;
      case 'no-spacing':
        transformedValue = transformedValue.replace(/\s+/g, '');
        break;
      default:
        break; // No spacing transformation
    }

    if (!allowSymbols) {
      transformedValue = transformedValue.replace(/[^\w\s-]/gi, ''); // Remove non-word, non-space, and non-hyphen characters
    }
//    console.log(`[TextFieldComponent - ${fieldId}] applyTransformations: transformed value: ${transformedValue}`);

    return transformedValue;
  }, [fieldId, fieldMetadata.transformations]);

  const debouncedOnChange = useCallback(debounce((fieldId, value) => {
//    console.log(`[TextFieldComponent - ${fieldId}] Debounced onChange called with value: ${value}`);
    onChange(fieldId, value);
  }, 300), [onChange]); // Adjust debounce delay as needed


  const handleChange = (event) => {
    let inputValue = event.target.value;
//    console.log(`[TextFieldComponent - ${fieldId}] handleChange: raw input value: ${inputValue}`);

    inputValue = applyTransformations(inputValue);

    if (fieldMetadata.maxLength && inputValue.length > fieldMetadata.maxLength) {
//      console.log(`[TextFieldComponent - ${fieldId}] handleChange: trimming input to maxLength: ${fieldMetadata.maxLength}`);
      inputValue = inputValue.substring(0, fieldMetadata.maxLength);
    }

//    console.log(`[TextFieldComponent - ${fieldId}] Change: ${inputValue}`); // Log the changed value
    setInternalValue(inputValue);
    // Use debouncedOnChange instead of onChange
    debouncedOnChange(fieldId, inputValue);
  };


  // Handle submit transformation if necessary
  useEffect(() => {
    if (isSubmitting) {
      const finalValue = fieldMetadata.transformations?.encode ? encodeURIComponent(internalValue) : internalValue;
//    console.log(`[TextFieldComponent - ${fieldId}] Submit: ${finalValue}`); // Log the final value on submit

      onChange(fieldId, finalValue);
    }
  }, [isSubmitting, internalValue, onChange, fieldId, fieldMetadata.transformations?.encode]);

  return (
    <FormControl fullWidth disabled={disabled}>
      <CustomFieldWrapper
        label={fieldMetadata.label}
        variant="outlined"
        value={internalValue}
        onChange={handleChange}
        required={fieldMetadata.required}
        error={fieldMetadata.externalError || false}
        helperText={fieldMetadata.externalError ? fieldMetadata.externalHelperText : fieldMetadata.helperText || ''}
        placeholder={fieldMetadata.hint}
        fullWidth
        InputProps={{
          inputProps: {
            maxLength: fieldMetadata.maxLength,
          },
        }}
        disabled={disabled || isSubmitting}
      />
    </FormControl>
  );
};

TextFieldComponent.propTypes = {
  fieldId: PropTypes.string.isRequired,
  fieldMetadata: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
    maxLength: PropTypes.number,
    externalError: PropTypes.bool,
    externalHelperText: PropTypes.string,
    helperText: PropTypes.string,
    transformations: PropTypes.object,
  }).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

export default TextFieldComponent;
