import { db } from '../firebase'; 
import { collection, getDocs, doc, getDoc, setDoc, onSnapshot, addDoc } from 'firebase/firestore'; 

export const getDropdownOptions = async () => {
  const options = {};
  const dropdownOptionsCol = collection(db, 'dropdownOptions');
  const snapshot = await getDocs(dropdownOptionsCol);
//  console.log("Fetching dropdown options from Firestore...");
  snapshot.forEach(docSnapshot => {
    // Sort options by the 'order' field
    const sortedOptions = docSnapshot.data().options.sort((a, b) => a.order - b.order);
//    console.log(`Option fetched for ${docSnapshot.id}:`, docSnapshot.data().options);
    options[docSnapshot.id] = sortedOptions;
  });
//  console.log("All dropdown options fetched:", options);
  return options;
};


export const subscribeToDropdownOptions = (callback) => {
  const dropdownOptionsCol = collection(db, 'dropdownOptions');
  return onSnapshot(dropdownOptionsCol, (snapshot) => {
    const options = {};
    snapshot.forEach(docSnapshot => {
      // Sort options by the 'order' field before passing to callback
      const sortedOptions = docSnapshot.data().options.sort((a, b) => a.order - b.order);
      options[docSnapshot.id] = sortedOptions;
    });
    callback(options);
  });
};

export const updateDropdownOptions = async (category, newOptions) => {
  console.log(`[updateDropdownOptions] Updating dropdown options for '${category}' with data:`, newOptions);

  // Ensure newOptions array is sorted by 'order' before updating
  const sortedOptions = newOptions.sort((a, b) => a.order - b.order);
  console.log(`[updateDropdownOptions] Sorted options for '${category}':`, sortedOptions);

  const optionsDoc = doc(db, 'dropdownOptions', category);

  try {
    await setDoc(optionsDoc, { options: sortedOptions }, { merge: true });
    console.log(`[updateDropdownOptions] Successfully updated options for '${category}'`);
  } catch (error) {
    console.error(`[updateDropdownOptions] Error updating options for '${category}':`, error);
    throw error; // Rethrowing the error so it can be caught elsewhere
  }
};



export const getDropdownDependencies = async () => {
  try {
    const docRef = doc(db, 'dropdownDependencies', 'dynamicDependencies');
    const docSnapshot = await getDoc(docRef);
    
    if (docSnapshot.exists()) {
      return docSnapshot.data();
    } else {
//      console.error('No dynamicDependencies found in Firestore!');
      return {};
    }
  } catch (error) {
//    console.error('Error fetching dynamic dependencies:', error);
    return {};
  }
};

export const subscribeToDropdownDependencies = (callback) => {
  const dependenciesDoc = doc(db, 'dropdownDependencies', 'dynamicDependencies');
  return onSnapshot(dependenciesDoc, (docSnapshot) => {
    if (docSnapshot.exists()) {
      callback(docSnapshot.data());
    }
  });
};

export const updateDropdownDependencies = async (newDependencies) => {
//  console.log("Updating dropdown dependencies with data:", newDependencies);

  const dependenciesDoc = doc(db, 'dropdownDependencies', 'dynamicDependencies');

  try {
    await setDoc(dependenciesDoc, newDependencies, { merge: true });
//    console.log("Successfully updated dropdown dependencies");
  } catch (error) {
    console.error("Error updating dropdown dependencies:", error);
    throw error; // Rethrowing the error so it can be caught in handleSave
  }
};

// Function to fetch field metadata
export const getFieldMetadata = async () => {
  const fieldMetadata = {};
  const metadataCollectionRef = collection(db, 'fieldMetadata');
  const snapshot = await getDocs(metadataCollectionRef);
  snapshot.forEach(doc => {
//        console.log(`Fetched field metadata for '${doc.id}':`, doc.data()); // Added log
    fieldMetadata[doc.id] = doc.data();
  });
  return fieldMetadata;
};


// Simplified function to add form details (including the screenshot URL) to Firestore
export const addFormWithScreenshotToFirestore = async (formDetails) => {
  try {
    const formRef = await addDoc(collection(db, 'forms'), {
      ...formDetails,
      createdAt: new Date(),
      lastModified: new Date(),
    });

//    console.log(`Form added with ID: ${formRef.id}`);
  } catch (error) {
    console.error('Error adding form to Firestore:', error);
  }
};

// Function to save the naming structure
export const saveNamingStructure = async (structure) => {
  const namingStructureDocRef = doc(db, 'namingStructures', 'current');
  try {
    await setDoc(namingStructureDocRef, { structure });
//    console.log("Successfully saved the naming structure");
  } catch (error) {
    console.error("Error saving the naming structure:", error);
  }
};

// Function to retrieve the naming structure
export const getNamingStructure = async () => {
  const namingStructureDocRef = doc(db, 'namingStructures', 'current');
  try {
    const docSnapshot = await getDoc(namingStructureDocRef);
    if (docSnapshot.exists()) {
      return docSnapshot.data().structure;
    } else {
//      console.log("No naming structure found");
      return '';
    }
  } catch (error) {
    console.error("Error fetching the naming structure:", error);
  }
};

// Function to fetch the form layout configuration
export const getLayoutConfig = async () => {
    try {
        // First, try to fetch the current layout
        const currentLayoutDocRef = doc(db, 'formLayouts', 'currentLayout');
        const currentLayoutDoc = await getDoc(currentLayoutDocRef);

        if (currentLayoutDoc.exists() && currentLayoutDoc.data().layout) {
            // If currentLayout exists and has a layout, return it
            return currentLayoutDoc.data().layout;
        } else {
            // If currentLayout doesn't exist or doesn't have a layout, fetch the defaultLayout
            const defaultLayoutDocRef = doc(db, 'formLayouts', 'defaultLayout');
            const defaultLayoutDoc = await getDoc(defaultLayoutDocRef);

            if (defaultLayoutDoc.exists()) {
                return defaultLayoutDoc.data().layout;
            } else {
//                console.log("No layout configuration found in 'defaultLayout'");
                return {};
            }
        }
    } catch (error) {
        console.error("Error fetching layout configuration:", error);
        return {};
    }
};



// Function to save the form layout configuration
export const saveLayoutConfig = async (layoutConfig) => {
  const layoutConfigDocRef = doc(db, 'formLayouts', 'currentLayout');
  try {
    await setDoc(layoutConfigDocRef, { layout: layoutConfig });
//    console.log("Successfully saved layout configuration");
  } catch (error) {
    console.error("Error saving layout configuration:", error);
    throw error;
  }
};