import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBJP6uXzgrYOTnD_qBiMrmW16ln8aveh8g",
  authDomain: "templateform-eab98.firebaseapp.com",
  projectId: "templateform-eab98",
  storageBucket: "templateform-eab98.appspot.com",
  messagingSenderId: "601782183494",
  appId: "1:601782183494:web:9b58953ab0ccb00edecc47"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

//console.log("Firestore instance (db):", db); // Console log the Firestore instance

export { db };