import React from 'react';
import Box from '@mui/material/Box';
import DropZone from './DropZone';
import DraggableItem from './DraggableItem';
import { DropZoneTypes } from './constants';

const FormLayout = ({ layoutConfig, fieldsMetadata, moveItem }) => {
    const rowKeys = Object.keys(layoutConfig).sort((a, b) => parseInt(a.replace('row', '')) - parseInt(b.replace('row', '')));

    return (
        <>
            {rowKeys.map((rowKey, rowIndex) => {
                if (!layoutConfig[rowKey]) {
                    console.error(`Row ${rowKey} is undefined in layoutConfig`);
                    return null;
                }

                const row = layoutConfig[rowKey];
                const isLastRow = rowIndex === rowKeys.length - 1;

                return (
                    <Box key={rowKey} style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        {/* BETWEENROWS Drop Zone for dropping between rows */}
                        {rowIndex > 0 && <DropZone onDrop={(item, type) => moveItem(item.rowIndex, item.index, rowIndex - 1, 0, type)} type={DropZoneTypes.BETWEENROWS} rowIndex={rowIndex - 1} fieldIndex={0} rowKeys={rowKeys} />}
                        {/* ABOVE Drop Zone for the first row */}
                        {rowIndex === 0 && <DropZone onDrop={(item, type) => moveItem(item.rowIndex, item.index, -1, 0, type)} type={DropZoneTypes.ABOVE} rowIndex={-1} fieldIndex={0} rowKeys={rowKeys} />}
                        <Box style={{ display: 'flex' }}>
                            {row.map((field, fieldIndex) => (
                                <React.Fragment key={`${field.id}-${rowIndex}-${fieldIndex}`}>
                                    <DraggableItem id={field.id} label={fieldsMetadata[field.id]?.label} type={fieldsMetadata[field.id]?.type} required={fieldsMetadata[field.id]?.required} index={fieldIndex} rowIndex={rowIndex} moveItem={moveItem} rowKeys={rowKeys} />
                                    {fieldIndex < row.length - 1 && <DropZone onDrop={(item, type) => moveItem(item.rowIndex, item.index, rowIndex, fieldIndex + 1, type)} type={DropZoneTypes.RIGHT} rowIndex={rowIndex} fieldIndex={fieldIndex + 1} rowKeys={rowKeys} />}
                                </React.Fragment>
                            ))}
                        </Box>
                        {/* BELOW Drop Zone for the last row */}
                        {isLastRow && <DropZone onDrop={(item, type) => moveItem(item.rowIndex, item.index, rowIndex + 1, 0, type)} type={DropZoneTypes.BELOW} rowIndex={rowIndex} fieldIndex={0} rowKeys={rowKeys} />}
                    </Box>
                );
            })}
        </>
    );
};

export default FormLayout;
