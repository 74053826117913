// components/Form/FormFeedback.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FormFeedback = ({ open, message, onClose }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
            message={message}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
        />
    );
};

FormFeedback.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

export default FormFeedback;
