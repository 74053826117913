import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel, Tooltip } from '@mui/material';

const CustomTableHeader = ({ columns, onSort, currentSortColumn, sortDirection }) => {
  const getAlignment = (columnType) => {
    switch (columnType) {
      case 'number':
        return 'right';
      case 'text':
      default:
        return 'left';
    }
  };

  const isColumnSorted = (columnId) => currentSortColumn === columnId;

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell 
            key={column.id} 
            align={getAlignment(column.type)}
            sx={{ 
              cursor: column.sortable ? 'pointer' : 'default',
              fontWeight: 'bold',
              backgroundColor: '#f5f5f5',
              fontSize: '0.80rem',
              lineHeight: '1.4',
              borderBottom: '1px solid rgba(224, 224, 224, 1)',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: isColumnSorted(column.id) ? '#1E88E5' : 'inherit', // Change color for sorted column
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
            }}
          >
            {column.sortable ? (
              <Tooltip title={`Sort by ${column.label}`}>
                <TableSortLabel
                  onClick={() => onSort(column.id)}
                  active={isColumnSorted(column.id)}
                  direction={isColumnSorted(column.id) ? sortDirection : 'asc'}
                >
                  {column.label}
                </TableSortLabel>
              </Tooltip>
            ) : (
              column.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHeader;
